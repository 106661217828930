export interface RegionDataType {
  companyNumber: string;
  name: string;
  regions: string[];
}

export interface AdvisorsRegionDataType {
  advisors: RegionDataType[];
  branchName: string;
  branchCode: string;
}

export const firstLevelKorean = {
  "Seoul Special City": "서울",
  "Gyeonggi-do Province": "경기",
  "Incheon Metropolitan City": "인천",
  "Gangwon-do Province": "강원",
  "Chungcheongbuk-do Province": "충북",
  "Sejong Special Self-Governing City": "세종",
  "Chungcheongnam-do Province": "충남",
  "Daejeon Metropolitan City": "대전",
  "Gyeongsangbuk-do Province": "경북",
  "Daegu Metropolitan City": "대구",
  "Ulsan Metropolitan City": "울산",
  "Busan Metropolitan City": "부산",
  "Gyeongsangnam-do Province": "경남",
  "Jeollabuk-do Province": "전북",
  "Jeollanam-do Province": "전남",
  "Gwangju Metropolitan City": "광주",
  "Jeju Special Self-Governing Province": "제주",
};

export const secondLevelKorean = {
  "Gangbuk-gu": "강북구",
  "Dobong-gu": "도봉구",
  "Nowon-gu": "노원구",
  "Jungnang-gu": "중랑구",
  "Dongdaemun-gu": "동대문구",
  "Seongbuk-gu": "성북구",
  "Jongno-gu": "종로구",
  "Eunpyeong-gu": "은평구",
  "Seodaemun-gu": "서대문구",
  "Mapo-gu": "마포구",
  "Yongsan-gu": "용산구",
  "Jung-gu": "중구",
  "Seongdong-gu": "성동구",
  "Gwangjin-gu": "광진구",
  "Gangdong-gu": "강동구",
  "Songpa-gu": "송파구",
  "Gangnam-gu": "강남구",
  "Seocho-gu": "서초구",
  "Dongjak-gu": "동작구",
  "Yeongdeungpo-gu": "영등포구",
  "Gangseo-gu": "강서구",
  "Yangcheon-gu": "양천구",
  "Guro-gu": "구로구",
  "Geumcheon-gu": "금천구",
  "Gwanak-gu": "관악구",
  "Gimpo-si": "김포시",
  "Goyang-si": "고양시",
  "Paju-si": "파주시",
  "Yeoncheon-gun": "연천군",
  "Pocheon-si": "포천시",
  "Dongducheon-si": "동두천시",
  "Yangju-si": "양주시",
  "Uijeongbu-si": "의정부시",
  "Guri-si": "구리시",
  "Namyangju-si": "남양주시",
  "Gapyeong-gun": "가평군",
  "Yangpyeong-si": "양평군",
  "Yeoju-si": "여주시",
  "Gwangju-si": "광주시",
  "Hanam-si": "하남시",
  "Seongnam-si": "성남시",
  "Gwacheon-si": "과천시",
  "Anyang-si": "안양시",
  "Gwangmyeong-si": "광명시",
  "Bucheon-si": "부천시",
  "Siheung-si": "시흥시",
  "Ansan-si": "안산시",
  "Gunpo-si": "군포시",
  "Uiwang-si": "의왕시",
  "Suwon-si": "수원시",
  "Yongin-si": "용인시",
  "Icheon-si": "이천시",
  "Anseong-si": "안성시",
  "Pyungtaek-si": "평택시",
  "Osan-si": "오산시",
  "Hwasung-si": "화성시",
  "Gyeyang-gu": "계양구",
  "Bupyeong-gu": "부평구",
  "Namdong-gu": "남동구",
  "Yeonsu-gu": "연수구",
  "Michuhol-gu": "미추홀구",
  "Dong-gu": "동구",
  "Seo-gu": "서구",
  "Ganghwa-gun": "강화군",
  "Ongjin-gun": "옹진군",
  "Cheorwon-gun": "철원군",
  "Hwacheon-gun": "화천군",
  "Chuncheon-si": "춘천시",
  "Yanggu-gun": "양구군",
  "Inje-gun": "인제군",
  "Goseong-gun": "고성군",
  "Sokcho-si": "속초시",
  "Yangyang-gun": "양양군",
  "Hongcheon-gun": "홍천군",
  "Hoengseong-gun": "횡성군",
  "Pyeongchang-gun": "평창군",
  "Gangneung-si": "강릉시",
  "Donghae-si": "동해시",
  "Samcheok-si": "삼척시",
  "Taebaek-si": "태백시",
  "Jeongseon-gun": "정선군",
  "Yeongwol-gun": "영월군",
  "Wonju-si": "원주시",
  "Danyang-gun": "단양군",
  "Jecheon-si": "제천시",
  "Chungju-si": "충주시",
  "Eumseong-gun": "음성군",
  "Jincheon-gun": "진천군",
  "Jeungpyeong-gun": "증평군",
  "Goesan-gun": "괴산군",
  "Cheongju-s": "청주시",
  "Boeun-gun": "보은군",
  "Okcheon-gun": "옥천군",
  "Yeongdong-gun": "영동군",
  "Sejong-si": "세종시",
  "Cheonan-si": "천안시",
  "Asan-si": "아산시",
  "Dangjin-si": "당진시",
  "Seosan-si": "서산시",
  "Taean-gun": "태안군",
  "Hongseong-si": "홍성군",
  "Yesan-gun": "예산군",
  "Gongju-si": "공주시",
  "Geumsan-gun": "금산군",
  "Gyeryong-si": "계룡시",
  "Nonsan-si": "논산시",
  "Buyeo-gun": "부여군",
  "Cheongyang-gun": "청양군",
  "Boryeong-si": "보령시",
  "Seocheon-gun": "서천군",
  "Yuseong-gu": "유성구",
  "Daedeok-gu": "대덕구",
  "Yeongju-si": "영주시",
  "Bonghwa-gun": "봉화군",
  "Uljin-gun": "울진군",
  "Yeongdeok-gun": "영덕군",
  "Andong-si": "안동시",
  "Yecheon-gun": "예천군",
  "Mungyeong-si": "문경시",
  "Sangju-si": "상주시",
  "Uiseong-gun": "의성군",
  "Cheongsong-gun": "청송군",
  "Pohang-si": "포항시",
  "Gyeongju-si": "경주시",
  "Cheongdo-gun": "청도군",
  "Gyeongsan-si": "경산시",
  "Yeongcheon-si": "영천시",
  "Gunwi-gun": "군위군",
  "Gumi-si": "구미시",
  "Gimcheon-si": "김천시",
  "Chilgok-gun": "칠곡군",
  "Seongju-gun": "성주군",
  "Goryeong-gun": "고령군",
  "Ulleung-gun": "울릉군",
  "Buk-gu": "북구",
  "Suseong-gu": "수성구",
  "Nam-gu": "남구",
  "Dalseo-gu": "달서구",
  "Dalseong-gun": "달성군",
  "Ulju-gun": "울주군",
  "Gijang-gun": "기장군",
  "Geumjeong-gu": "금정구",
  "Sasang-gu": "사상구",
  "Busanjin-gu": "부산진구",
  "Yeonje-gu": "연제구",
  "Dongnae-gu": "동래구",
  "Haeundae-gu": "해운대구",
  "Suyeong-gu": "수영구",
  "Yeongdo-gu": "영도구",
  "Saha-gu": "사하구",
  "Hamyang-gun": "함양군",
  "Geochang-gun": "거창군",
  "Hapcheon-gun": "합천군",
  "Changnyeong-gun": "창녕군",
  "Miryang-si": "밀양시",
  "Yangsan-si": "양산시",
  "Gimhae-si": "김해시",
  "Changwon-si": "창원시",
  "Haman-gun": "함안군",
  "Uiryeong-gun": "의령군",
  "Sancheong-gun": "산청군",
  "Hadong-gun": "하동군",
  "Namhae-gun": "남해군",
  "Sacheon-si": "사천시",
  "Jeonju-si": "진주시",
  "Tongyeong-si": "통영시",
  "Geoje-si": "거제시",
  "Gunsan-si": "군산시",
  "Gimje-si": "김제시",
  "Iksan-si": "익산시",
  "Wanju-gun": "완주군",
  "Jinan-gun": "진안군",
  "Muju-gun": "무주군",
  "Jangsu-gun": "장수군",
  "Namwon-si": "남원시",
  "Imsil-gun": "임실군",
  "Sunchang-gun": "순창군",
  "Jeongeup-si": "정읍시",
  "Buan-gun": "부안군",
  "Gochang-gun": "고창군",
  "Yeonggwang-gun": "영광군",
  "Hampyeong-gun": "함평군",
  "Jangseong-gun": "장성군",
  "Damyang-gun": "담양군",
  "Gokseong-gun": "곡성군",
  "Gurye-gun": "구례군",
  "Gwangyang-si": "광양시",
  "Suncheon-si": "순천시",
  "Hwasun-gun": "화순군",
  "Naju-si": "나주시",
  "Yeongam-gun": "영암군",
  "Muan-gun": "무안군",
  "Mokpo-si": "목포시",
  "Sinan-gun": "신안군",
  "Jindo-gun": "진도군",
  "Haenam-gun": "해남군",
  "Wando-gun": "완도군",
  "Gangjin-gun": "강진군",
  "Jangheung-gun": "장흥군",
  "Boseong-gun": "보성군",
  "Goheung-gun": "고흥군",
  "Yeosu-si": "여수시",
  "Gwangsan-gu": "광산구",
  "Jeju-si": "제주시",
  "Seogwipo-si": "서귀포시",
};

export interface MediaCodeDataType {
  categoryId: number;
  leadType: string;
  mediaCode: string;
  mediaName: string;
  createDate: string;
  rowNumber?: number;
}

export interface MediaCodeOptionsDataType {
  LD: string;
  BD: string[];
}
