import { Children } from "react";

export interface LeadDataType {
  assignee: string;
  callLogStatus: string;
  cldl: string;
  companyNumber: string;
  creationDate: string;
  deletionStatus: boolean | null;
  departCode: string;
  departName: string;
  distributionDate: string;
  expiryDate: string;
  isDuplicate: boolean;
  leadCycle: string;
  leadNo: number;
  leadType: string;
  media: string;
  refundStatus: boolean | null;
}

export interface AdvisorInfoType {
  companyNumber: string;
  name: string;
  isManager: boolean;
}

export interface BranchInfoType {
  branchName: string;
  branchCode: string;
  advisors: AdvisorInfoType[];
}

export interface ChildrenInfoType {
  name: string;
  birthday: string;
}

export interface ProspectInformationType {
  customerNumber: string | null;
  contractStatus: string | null;
  contractDate: string;
  name: string;
  email: string;
  phoneNumber: string;
  zipCode: string;
  address: string;
  relationship: string;
  isPregnant: boolean | null;
  dueDate: string | null;
  children: ChildrenInfoType[];
  memo: string;
}

export interface LeadInformationType {
  name: string;
  companyNumber: string;
  term: string;
  cldl: string | null;
  leadType: string;
  mediaCode: string;
  media: string;
  distributionDate: string;
  expiryDate: string;
  createDate: string;
}

export interface LeadDistributionType {
  term: string;
  branchName: string;
  branchCode: string;
  name: string;
  companyNumber: string;
  distributionDate: string;
  expiryDate: string;
  changeDate: string;
  memo: string;
  isKeep: boolean;
}

export interface LeadDuplicationType {
  leadNo: 0;
  cldl: string | null;
  leadType: string;
  media: string;
  createDate: string;
  branchName: string;
  branchCode: string;
  name: string;
  companyNumber: string;
  contractStatus: string | null;
  contractDate: string;
  memo: string;
}

export interface LeadRecordType {
  apoId: 0;
  type: string | null;
  isCanceled: boolean | null;
  term: string;
  branchName: string;
  branchCode: string;
  name: string;
  companyNumber: string;
  createDate: string;
  scheduledDate: string;
  consultWith: string;
  feedback: string;
  concern: string;
  timeSpent: string;
  memo: string;
}

export interface LeadRefundType {
  refundId: 0;
  term: string;
  branchName: string;
  branchCode: string;
  name: string;
  companyNumber: string;
  reasonCode: 0;
  status: string | null;
  receiptDate: string;
}

export interface LeadDetailDataType {
  prospectInformation: ProspectInformationType;
  leadInformation: LeadInformationType;
  leadDistributionList: LeadDistributionType[];
  leadDuplicationList: LeadDuplicationType[];
  leadRecordList: LeadRecordType[];
  leadRefundList: LeadRefundType[];
}

export interface QueryFormValues {
  type: string[] | undefined;
  cycle: string | undefined;
  assignee: string | undefined;
  log: string | undefined;
  keep: string | undefined;
  duplicate: string | undefined;
  refund: string | undefined;
  deletion: string | undefined;
  distribution: any | undefined;
  expiry: any | undefined;
  creation: any | undefined;
  number: string | undefined;
  prospect: string | undefined;
  phone: string | undefined;
  child: string | undefined;
  address: string | undefined;
}

export interface UploadExcelDataFormat {
  Address1: string;
  Address2?: string;
  BULD_NO?: string;
  C1IsMan?: any;
  CBIRTH1: string;
  CNAME1: string;
  EMAIL?: string;
  EMD_CD?: string;
  IsPregnant?: any;
  KNAME: string;
  LIDNO?: string;
  MBPHONE: string;
  MEDIA: string;
  MEMO?: string;
  ParentRelation?: string;
  ROAD_CD?: string;
  SGG_CD?: string;
  TEL?: string;
  ZIP: string;
}

export interface FormattedUploadExcelDataFormat {
  name: string;
  mobileNumber: string;
  media: string;
  zipCode: string;
  address1: string;
  address2?: string;
  buildingNumber?: string;
  emdCode?: string;
  companyNumber?: string;
  roadCode?: string;
  sggCode?: string;
  parentRelation?: string;
  isPregnant?: boolean;
  dueDate?: string;
  childName?: string;
  childBirthDate?: string;
  childGender?: string;
  memo?: string;
}

export interface ExcelErrorFormat {
  row: number;
  error: string;
}

export enum ParentRelationEnum {
  Other = "기타",
  Mother = "모",
  Father = "부",
}

export interface AnalyzedDataFormat {
  assignedCompanyNumber: string;
  isInvalidCompanyNumber: boolean;
  autoAssignedCompanyNumber: string | null;
  assignFailReason: string | null;
  assignedAdvisorName: string | null;
  assignedBranchCode: string | null;
  assignedBranchName: string | null;
  categoryId: number | null;
  duplicateWithIndex: number | null;
  duplicateWithMemberId: number | null;
  isInvalidLead: boolean;
  isTestData: boolean;
  encryptedMobileNumber: string;
}

export interface CreateCLSuccessResponse {
  branchCode: string; // "HQ115"
  branchName: string; // "서울잠실지사"
  companyNumber: string; // "S10095"
  name: string; // "구선정"
  counts: Record<string, number>; // { 네이버브랜드광고_DEMO: 1, 네이버브랜드광고_DIP: 1, total: 1 }
}

export interface CreateCLFailedResponse {
  index: number;
  mobileNumber: string;
  isIgnored: boolean;
  assignFailReason: string;
  databaseErrorMessage: string;
}
