import {
  UploadExcelDataFormat,
  ExcelErrorFormat,
  ParentRelationEnum,
} from "../types";

const isValidRelation = (value: string): boolean => {
  return Object.values(ParentRelationEnum).includes(
    value as ParentRelationEnum
  );
};

export const checkExcelData = (data: UploadExcelDataFormat[]) => {
  let pass = true;
  const errorList: ExcelErrorFormat[] = [];
  data.forEach((lead, index) => {
    if (!lead.KNAME) {
      errorList.push({ row: index + 2, error: "name-empty" });
      pass = false;
    }
    // if (lead.KNAME && lead.KNAME.length > 3) {
    //   errorList.push({ row: index + 2, error: "name-length" });
    //   pass = false;
    // }
    if (!lead.CNAME1) {
      errorList.push({ row: index + 2, error: "name-empty" });
      pass = false;
    }
    if (!lead.CBIRTH1) {
      errorList.push({ row: index + 2, error: "birthday" });
      pass = false;
    }
    // if (lead.CNAME1 && lead.CNAME1.length > 3) {
    //   errorList.push({ row: index + 2, error: "name-length" });
    //   pass = false;
    // }
    if (!lead.MBPHONE) {
      errorList.push({ row: index + 2, error: "phone" });
      pass = false;
    }
    if (lead.ParentRelation && !isValidRelation(lead.ParentRelation)) {
      errorList.push({ row: index + 2, error: "relation" });
      pass = false;
    }
    if (!lead.MEDIA) {
      errorList.push({ row: index + 2, error: "media" });
      pass = false;
    }
    if (!lead.ZIP) {
      errorList.push({ row: index + 2, error: "zip" });
      pass = false;
    }
    if (!lead.Address1) {
      errorList.push({ row: index + 2, error: "address1" });
      pass = false;
    }
  });
  return { isPass: pass, errorList: errorList };
};
