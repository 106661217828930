import { CreateCLSuccessResponse } from "../types";
export function transformCLSuccessResponse(
  data: CreateCLSuccessResponse[]
): Record<string, any>[] {
  const allKeys = new Set<string>();

  data.forEach(({ counts }) => {
    Object.keys(counts).forEach((key) => {
      if (key !== "total") {
        allKeys.add(key);
      }
    });
  });

  return data.map(({ counts, ...rest }) => {
    const expandedCounts: Record<string, any> = {};

    allKeys.forEach((key) => {
      expandedCounts[key] = counts[key] ?? "";
    });

    return {
      ...rest,
      ...expandedCounts,
      total: counts.total,
    };
  });
}

// 示例数据
const inputData: CreateCLSuccessResponse[] = [
  {
    branchCode: "HQ115",
    branchName: "서울잠실지사",
    companyNumber: "S10095",
    name: "구선정",
    counts: { 네이버브랜드광고_DEMO: 1, 네이버브랜드광고_DIP: 1, total: 2 },
  },
  {
    branchCode: "HQ116",
    branchName: "서울강남지사",
    companyNumber: "S10096",
    name: "김철수",
    counts: { bb_DEMO: 1, zz_DIP: 1, total: 2 },
  },
  {
    branchCode: "HQ117",
    branchName: "서울서초지사",
    companyNumber: "S10097",
    name: "이영희",
    counts: { bb_DEMO: 1, aa_DIP: 1, total: 2 },
  },
];

const outputData = transformCLSuccessResponse(inputData);
console.log(outputData);
