export const leadTypeOptions = [
  {
    value: "CL",
    title: "CL",
    children: [
      { value: "U", title: "U" },
      { value: "NC", title: "NC" },
      { value: "P", title: "P" },
    ],
  },
  {
    value: "DL",
    title: "DL",
    children: [
      { value: "LD", title: "LD" },
      { value: "GD", title: "GD" },
      { value: "BD", title: "BD" },
      { value: "PD", title: "PD" },
    ],
  },
];

export const cycleOptions = [
  { label: "Fresh", value: "fresh" },
  { label: "Recycle", value: "recycle" },
  { label: "Old", value: "old" },
  { label: "Sleep", value: "sleep" },
];

export const logStatusOptions = [
  { value: "pending", label: "미진행", color: "#D9D9D9" },
  { value: "appo", label: "아포", color: "#C7894B" },
  { value: "unappo", label: "아포취소", color: "#FED241" },
  { value: "demo", label: "데모", color: "#00B140" },
  { value: "contract", label: "계약완료", color: "#F04950" },
];

export const keepOptions = [
  { label: "해당없음", value: "false" },
  { label: "Keep", value: "true" },
];

export const duplicateOptions = [
  { label: "해당없음", value: "false" },
  { label: "중복", value: "true" },
];

export const refundOptions = [
  { label: "해당없음", value: "" },
  { label: "신청완료", value: "refunded" },
  { label: "리펀드완료", value: "requested" },
];

export const deleteOptions = [
  { label: "해당없음", value: "" },
  { label: "신청완료", value: "deleted" },
  { label: "삭제완료", value: "requested" },
];

export const refundReasonList = [
  { label: "Available phone call BUT didn't request for IP", value: 1 },
  { label: "Unavailable phone call", value: 2 },
  { label: "Foreigner", value: 3 },
  { label: "Request from same family", value: 4 },
  { label: "IP request from non-parents", value: 5 },
  { label: "Returning customer", value: 6 },
  { label: "Refuse approach", value: 7 },
];

export const refundStatusList = [
  {
    label: "리펀드 요청",
    value: "requested",
  },
  {
    label: "리펀드 처리완료",
    value: "completed",
  },
  {
    label: "리펀드 요청 철회",
    value: "canceled",
  },
];

export const deleteStatusList = [
  {
    label: "삭제 요청",
    value: "requested",
  },
  {
    label: "삭제 처리완료",
    value: "completed",
  },
  {
    label: "삭제 요청 철회",
    value: "canceled",
  },
];

export const excelErrorList = [
  {
    label: "name-length",
    value: "잘못된 이름 형식",
  },
  {
    label: "name-empty",
    value: "KNAME 누락",
  },
  {
    label: "zip",
    value: "ZIP 누락",
  },
  {
    label: "phone",
    value: "MBPHONE 누락",
  },
  {
    label: "birthday",
    value: "CBIRTH1 누락",
  },
  {
    label: "relation",
    value: '"엄마", "아빠", "그 외"만 허용됨',
  },
  {
    label: "media",
    value: "MEDIA 누락",
  },
  {
    label: "address1",
    value: "Address1 누락",
  },
];

export const AnalyzedErrorList = [
  {
    label: "companyNumber",
    value: "에게 직접 배정됩니다.",
  },
  {
    label: "media",
    value: "존재하지 않는 미디어명 입니다.",
  },
  {
    label: "row-duplicate",
    value: "엑셀 파일에서 N번째 행과 중복됩니다.",
  },
  {
    label: "member-duplicate",
    value: "데이터베이스 기록(회원 ID: XXXX)과 중복됩니다.",
  },
  {
    label: "no-kid",
    value: "임신중이 아니거나 6세 이하의 자녀가 없습니다.",
  },
  {
    label: "test",
    value: "테스트 데이터",
  },
];
