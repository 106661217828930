const specialCases: Record<string, string> = {
  M00142_TMA: "체험앱_DIP",
  M00143_TMA_Demo: "체험앱_DEMO",
};

export function convertToMediaName(input: string): string {
  if (specialCases[input]) {
    return specialCases[input];
  }

  return input.replace(/^M\d{5}\s*/, "");
}
