import { Button, Modal, Table, Upload, message, Collapse } from "antd";
import { useState, useEffect } from "react";
import { apiCall } from "../../../utils/apiCall";
import {
  UploadOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import { excelErrorList, AnalyzedErrorList } from "./options";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";
import { Dispatch, SetStateAction } from "react";
import { useReactiveVar } from "@apollo/client";
import { __currentUser__ } from "../../../graphql/policies";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import { ServiceTypes } from "../../../types/types";
import { transformCLSuccessResponse } from "./utils/transformCLSuccessResponse";
import { convertToMediaName } from "./utils/convertToMediaName";
import { checkExcelData } from "./utils/checkExcelData";
import {
  UploadExcelDataFormat,
  ExcelErrorFormat,
  AnalyzedDataFormat,
  FormattedUploadExcelDataFormat,
  CreateCLFailedResponse,
} from "./types";
import ContentTitle from "../../../components/ui/ContentTitle";
import "./leadRegistration.scss";
import noDataIcon from "../../../assets/icons/no-data.png";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import dayjs from "dayjs";

interface LeadRegistrationProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const LeadRegistration = ({
  setSelectedService,
  setSelectSubItem,
}: LeadRegistrationProps) => {
  useEffect(() => {
    setSelectedService(ServiceTypes.SALES_188);
    setSelectSubItem("신규리드 등록");
  }, []);
  const currentUser = useReactiveVar(__currentUser__);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 5,
    },
  });
  const [uploadedData, setUploadedData] = useState<
    UploadExcelDataFormat[] | any
  >([]);
  const [formattedData, setFormattedData] = useState<
    FormattedUploadExcelDataFormat[] | any
  >([]);
  const [analyzedData, setAnalyzedData] = useState<AnalyzedDataFormat[]>([]);
  const [shouldShowTable, setShouldShowTable] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successTableData, setSuccessTableData] = useState<any>([]);
  const [failedTableData, setFailedTableData] = useState<any>([]);
  const [performanceData, setPerformanceData] = useState<any>([]);
  const [duplicateTableData, setDuplicateTableData] = useState<any>([]);
  const [dataErrorList, setDataErrorList] = useState<ExcelErrorFormat[]>([]);
  const [modalOpen, setModalOpen] = useState(0);

  const header = {
    headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
    },
  };

  const toggleExpand = (key: string) => {
    setExpandedKeys((prev) =>
      prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key]
    );
  };

  const columns = [
    {
      title: "상태",
      width: 70,
      render: (_: any, __: any, index: number) => {
        const isFailed =
          !analyzedData[index]?.categoryId ||
          analyzedData[index]?.duplicateWithIndex ||
          analyzedData[index]?.duplicateWithMemberId ||
          analyzedData[index]?.isInvalidCompanyNumber ||
          analyzedData[index]?.isInvalidLead ||
          analyzedData[index]?.isTestData;
        return isFailed ? (
          <CloseCircleTwoTone twoToneColor="#EC1C24" />
        ) : (
          <CheckCircleTwoTone twoToneColor="#00B140" />
        );
      },
    },
    {
      title: "Media",
      dataIndex: "media",
      key: "media",
    },
    {
      title: "KNAME",
      dataIndex: "name",
      key: "name",
      width: 100,
    },
    {
      title: "MBPHONE",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
    {
      title: "ZIP",
      dataIndex: "zipCode",
      key: "zipCode",
    },
    {
      title: "Address1",
      dataIndex: "address1",
      key: "address1",
    },
    {
      title: "CNAME1",
      dataIndex: "childName",
      key: "childName",
      width: 100,
    },
    {
      title: "CBIRTH1",
      dataIndex: "childBirthDate",
      key: "childBirthDate",
    },
    {
      title: "Assigned AD",
      width: 120,
      render: (_: any, __: any, index: number) => {
        return (
          <div
            className={
              analyzedData[index]?.isInvalidCompanyNumber ? "red-text" : ""
            }
          >
            {analyzedData[index]?.assignedCompanyNumber}
          </div>
        );
      },
    },
    {
      title: "Auto-assigned AD",
      width: 120,
      render: (_: any, __: any, index: number) => {
        return (
          <div
            className={analyzedData[index]?.assignFailReason ? "red-text" : ""}
          >
            {analyzedData[index]?.autoAssignedCompanyNumber ||
              analyzedData[index]?.assignFailReason}
          </div>
        );
      },
    },
    {
      title: "Advisor name",
      width: 120,
      render: (_: any, __: any, index: number) =>
        analyzedData[index]?.assignedAdvisorName,
    },
    {
      title: "Branch code",
      width: 120,
      render: (_: any, __: any, index: number) =>
        analyzedData[index]?.assignedBranchCode,
    },
    {
      title: "Branch name",
      width: 120,
      render: (_: any, __: any, index: number) =>
        analyzedData[index]?.assignedBranchName,
    },
  ];

  const errorColumns = [
    { title: "행", dataIndex: "row", key: "row" },
    {
      title: "오류",
      dataIndex: "error",
      key: "error",
      render: (type: string) =>
        excelErrorList.find((item) => item.label === type)?.value,
    },
  ];

  const resetToStart = () => {
    setShouldShowTable(false);
    setAnalyzedData([]);
    setDataErrorList([]);
    setUploadedData([]);
    setFormattedData([]);
    setExpandedKeys([]);
    setIsError(false);
    setIsSuccess(false);
  };

  const handleFileUpload = (file: File) => {
    const reader = new FileReader();

    resetToStart();

    reader.onload = (e) => {
      if (!e.target?.result) {
        message.error("Fail to upload file!");
        return;
      }

      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      let jsonData = XLSX.utils.sheet_to_json(sheet);

      const columnMapping: Record<string, string> = {
        미디어코드: "MEDIA",
        이름: "KNAME",
        휴대폰번호: "MBPHONE",
        우편번호: "ZIP",
        주소1: "Address1",
        자녀1: "CNAME1",
        "자녀1 생일": "CBIRTH1",
        주소2: "Address2",
        "시/군/구 코드": "SGG_CD",
        "도로명 코드": "ROAD_CD",
        "법정동/법정리 코드": "EMD_CD",
        건물관리번호: "BULD_NO",
        관계: "ParentRelation",
        메모: "Memo",
      };

      jsonData = jsonData.map((row: any) => {
        const newRow: Record<string, any> = {};

        Object.keys(row).forEach((key) => {
          const newKey = columnMapping[key] || key;
          newRow[newKey] = row[key];
        });

        if (newRow.CBIRTH1) {
          if (typeof newRow.CBIRTH1 === "number" && newRow.CBIRTH1 > 30000) {
            const date = XLSX.SSF.parse_date_code(newRow.CBIRTH1);
            newRow.CBIRTH1 = `${date.y}-${String(date.m).padStart(
              2,
              "0"
            )}-${String(date.d).padStart(2, "0")}`;
          } else if (typeof newRow.CBIRTH1 === "string") {
            const parsedDate = dayjs(newRow.CBIRTH1, [
              "YYYY-MM-DD",
              "YYYY/MM/DD",
              "MM-DD-YYYY",
              "DD/MM/YYYY",
            ]);

            if (parsedDate.isValid()) {
              newRow.CBIRTH1 = parsedDate.format("YYYY-MM-DD");
            }
          }
        }

        newRow.MEDIA = convertToMediaName(newRow.MEDIA);

        return newRow;
      });

      setUploadedData(jsonData);
    };

    reader.readAsBinaryString(file);
    return false;
  };

  const analyzeData = async (payload: UploadExcelDataFormat[]) => {
    setIsLoading(true);
    const data = await apiCall({
      method: "POST",
      header: header,
      endPoint: process.env.REACT_APP_SALES_ADMIN + "/leads/excel/CL/analyze",
      payload: { CLData: payload },
    });
    setAnalyzedData(data.analysisData || []);
    setIsLoading(false);
    setShouldShowTable(true);
  };

  const handleFinalCreate = async () => {
    const payload = {
      CLData: formattedData,
      analysisData: analyzedData,
    };
    const data = await apiCall({
      method: "POST",
      header: header,
      endPoint: process.env.REACT_APP_SALES_ADMIN + "/leads/excel/CL",
      payload: payload,
    });
    if (data) {
      const hasError = data.failedData.some((i: CreateCLFailedResponse) => {
        return !!i.databaseErrorMessage;
      });

      if (hasError) {
        setIsError(true);
        return;
      }
      const successData = transformCLSuccessResponse(data.successData);
      const successColumnMapping: Record<string, string> = {
        branchCode: "HQ",
        branchName: "BRANCH",
        companyNumber: "AD",
        name: "AD NAME",
        total: "TOTAL",
      };
      const duplicateColumnMapping: Record<string, string> = {
        branchCode: "HQ",
        branchName: "BRANCH",
        companyNumber: "AD",
        advisorName: "AD NAME",
        leadNo: "LEAD",
        sourceType: "SOURCE",
        media: "MEDIA",
      };
      const failedColumnMapping: Record<string, string> = {
        index: "ROW",
        assignFailReason: "FAIL REASON",
        isIgnored: "IGNORE",
        mobileNumber: "MBPHONE",
      };
      const successTransformedData = successData.map((item) => {
        const newItem: Record<string, any> = {};
        Object.keys(item).forEach((key) => {
          newItem[successColumnMapping[key] || key] = item[key]; // 替換欄位名稱，未定義的保持原樣
        });
        return newItem;
      });
      const duplicateTransformedData = data.duplicateData.map((item: any) => {
        const newItem: Record<string, any> = {};

        Object.keys(duplicateColumnMapping).forEach((key) => {
          if (key in item) {
            newItem[duplicateColumnMapping[key]] = item[key];
          }
        });

        return newItem;
      });
      const failedTransformedData = data.failedData.map((item: any) => {
        const newItem: Record<string, any> = {};

        Object.keys(failedColumnMapping).forEach((key) => {
          if (key in item) {
            newItem[failedColumnMapping[key]] =
              key === "index" ? item[key] + 2 : item[key]; // 🔹 index +2
          }
        });

        return newItem;
      });
      setSuccessTableData(successTransformedData);
      setDuplicateTableData(duplicateTransformedData);
      setFailedTableData(failedTransformedData);
      setIsSuccess(true);
      setShouldShowTable(false);
    }
  };

  const fetchAdvisorPerformance = async () => {
    const data = await apiCall({
      method: "GET",
      endPoint:
        process.env.REACT_APP_SALES_ADMIN + "/advisors/admin/performance",
      header: header,
    });
    setPerformanceData(data);
  };

  const exportPerformanceExcel = () => {
    const wb = XLSX.utils.book_new();

    if (performanceData.length > 0) {
      const wsPerformance = XLSX.utils.json_to_sheet(performanceData);
      XLSX.utils.book_append_sheet(wb, wsPerformance, "Performance Data");
    }

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    FileSaver.saveAs(
      data,
      `advisor-performance-${dayjs().format("YYYY-MM-DD")}.xlsx`
    );
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();

    if (successTableData.length > 0) {
      const wsSuccess = XLSX.utils.json_to_sheet(successTableData);
      XLSX.utils.book_append_sheet(wb, wsSuccess, "Success Data");
    }

    if (failedTableData.length > 0) {
      const wsFailed = XLSX.utils.json_to_sheet(failedTableData);
      XLSX.utils.book_append_sheet(wb, wsFailed, "Failed Data");
    }

    if (duplicateTableData.length > 0) {
      const wsDuplicate = XLSX.utils.json_to_sheet(duplicateTableData);
      XLSX.utils.book_append_sheet(wb, wsDuplicate, "Duplicate Data");
    }

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    FileSaver.saveAs(
      data,
      `CLUploadResult-${dayjs().format("YYYY-MM-DD")}.xlsx`
    );
  };

  useEffect(() => {
    if (uploadedData.length > 0) {
      if (checkExcelData(uploadedData).isPass) {
        const today = dayjs().format("YYYY-MM-DD");
        const payload = uploadedData.map((lead: UploadExcelDataFormat) => {
          const requiredFields = {
            name: lead.KNAME,
            mobileNumber: lead.MBPHONE,
            media: lead.MEDIA,
            zipCode: lead.ZIP.toString(),
            address1: lead.Address1,
          };
          const optionalFields = {
            address2: lead.Address2,
            buildingNumber: lead.BULD_NO,
            emdCode: lead.EMD_CD,
            companyNumber: lead.LIDNO,
            roadCode: lead.ROAD_CD,
            sggCode: lead.SGG_CD,
            parentRelation: lead.ParentRelation,
            isPregnant:
              lead.CBIRTH1 && dayjs(lead.CBIRTH1).isAfter(dayjs(today))
                ? true
                : lead.IsPregnant
                ? Boolean(lead.IsPregnant)
                : null,
            dueDate:
              lead.CBIRTH1 && dayjs(lead.CBIRTH1).isAfter(dayjs(today))
                ? lead.CBIRTH1
                : null,
            childName: lead.CNAME1,
            childBirthDate:
              lead.CBIRTH1 && dayjs(lead.CBIRTH1).isBefore(dayjs(today))
                ? lead.CBIRTH1
                : null,
            childGender:
              lead.C1IsMan === undefined || lead.C1IsMan === null
                ? null
                : Boolean(lead.C1IsMan)
                ? "male"
                : "female",
            memo: lead.MEMO,
          };
          const finalData = Object.entries(optionalFields).reduce(
            (acc, [key, val]) =>
              val !== undefined && val !== null ? { ...acc, [key]: val } : acc,
            { ...requiredFields }
          );
          return finalData;
        });
        setFormattedData(payload);
        analyzeData(payload);
      } else {
        setDataErrorList(checkExcelData(uploadedData).errorList);
        setModalOpen(1);
      }
    }
  }, [uploadedData]);

  useEffect(() => {
    fetchAdvisorPerformance();
  }, []);

  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Lead Management"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div id="leadRegistrationWrapper">
      <ContentTitle title="신규리드 등록" />
      <div className="content-container">
        {!isError && !isSuccess && (
          <div className="flex justify-between items-center">
            <p className="text-base">엑셀 리드 목록</p>
            <div className="flex items-center">
              <Button className="mr-2" onClick={exportPerformanceExcel}>
                Advisor's Performance
              </Button>
              <Upload beforeUpload={handleFileUpload} showUploadList={false}>
                <Button icon={<UploadOutlined />} className="mr-2">
                  엑셀 업로드
                </Button>
              </Upload>
              <img
                className="icon"
                alt="expand-icon"
                src={require("../../../assets/icons/expand.png")}
              />
            </div>
          </div>
        )}
        {!isError && !isSuccess && (
          <Table
            className="mt-5"
            columns={columns}
            dataSource={
              shouldShowTable
                ? formattedData.map((v: any, i: number) => ({ ...v, key: i }))
                : []
            }
            loading={isLoading}
            scroll={{ x: "max-content", y: 410 }}
            pagination={false}
            rowClassName={(_, index) => {
              const isFailed =
                !analyzedData[index]?.categoryId ||
                analyzedData[index]?.duplicateWithIndex ||
                analyzedData[index]?.duplicateWithMemberId ||
                analyzedData[index]?.isInvalidCompanyNumber ||
                analyzedData[index]?.isInvalidLead ||
                analyzedData[index]?.isTestData;
              return isFailed ? "red-text" : "";
            }}
            rowKey="mobileNumber"
            expandable={{
              expandedRowKeys: expandedKeys,
              onExpand: (expanded, record) => toggleExpand(record.mobileNumber),
              rowExpandable: (record) => {
                const index = record.key;
                const isFailed =
                  !analyzedData[index]?.categoryId ||
                  analyzedData[index]?.duplicateWithIndex ||
                  analyzedData[index]?.duplicateWithMemberId ||
                  analyzedData[index]?.isInvalidCompanyNumber ||
                  analyzedData[index]?.isInvalidLead ||
                  analyzedData[index]?.isTestData;
                return Boolean(isFailed);
              },
              expandedRowRender: (record, index) => {
                const {
                  categoryId,
                  duplicateWithIndex,
                  duplicateWithMemberId,
                  isInvalidCompanyNumber,
                  isInvalidLead,
                  isTestData,
                } = analyzedData[index];
                return (
                  <div>
                    <ul style={{ listStyleType: "disc", paddingLeft: 20 }}>
                      {duplicateWithMemberId && (
                        <li>
                          {`${AnalyzedErrorList.find(
                            (i) => i.label === "member-duplicate"
                          )?.value.replace(
                            "XXXX",
                            duplicateWithMemberId.toString()
                          )}`}
                        </li>
                      )}
                      {duplicateWithIndex && (
                        <li>
                          {`${AnalyzedErrorList.find(
                            (i) => i.label === "row-duplicate"
                          )?.value.replace(
                            "N",
                            (duplicateWithIndex + 2).toString()
                          )}`}
                        </li>
                      )}
                      {!categoryId && (
                        <li>
                          {
                            AnalyzedErrorList.find((i) => i.label === "media")
                              ?.value
                          }
                        </li>
                      )}
                      {isInvalidCompanyNumber && (
                        <li>
                          {
                            AnalyzedErrorList.find(
                              (i) => i.label === "companyNumber"
                            )?.value
                          }
                        </li>
                      )}
                      {isInvalidLead && (
                        <li>
                          {
                            AnalyzedErrorList.find((i) => i.label === "no-kid")
                              ?.value
                          }
                        </li>
                      )}
                      {isTestData && (
                        <li>
                          {
                            AnalyzedErrorList.find((i) => i.label === "test")
                              ?.value
                          }
                        </li>
                      )}
                    </ul>
                  </div>
                );
              },
            }}
            locale={{
              emptyText: (
                <div className="text-center">
                  <div className="flex justify-center">
                    <img src={noDataIcon} className="no-icon" />
                  </div>
                  <p className="mt-5 opacity-85 text-black">
                    작성한 엑셀 양식을 업로드해주세요.
                  </p>
                  <p className="mt-2">xls, xlsx인 확장자 파일만 가능합니다.</p>
                </div>
              ),
            }}
          />
        )}
        {(isError || isSuccess) && (
          <div>
            <div className="flex justify-center">
              {isError ? (
                <CloseCircleTwoTone
                  twoToneColor="#EC1C24"
                  style={{ fontSize: "80px", marginTop: 40, marginBottom: 30 }}
                />
              ) : (
                <CheckCircleTwoTone
                  twoToneColor="#00B140"
                  style={{ fontSize: "80px", marginTop: 40, marginBottom: 30 }}
                />
              )}
            </div>
            {isError ? (
              <div>
                <p className="text-center text-2xl">오류가 발생했습니다.</p>
                <p className="text-center text-2xl mt-1">
                  즉시 개발자에게 문의하세요.
                </p>
              </div>
            ) : (
              <div>
                <p className="text-center text-2xl">
                  리드등록 및 배분이 완료되었습니다.
                </p>
                <p className="text-center text-sm">
                  배분된 리드 목록을 다운로드하여 엑셀에서 확인하세요.
                </p>
                <p className="text-center text-sm mt-1">
                  이 페이지를 벗어나면 다시 목록을 받을 수 없습니다.
                </p>
              </div>
            )}
            {isError ? (
              <div className="flex justify-center mt-5">
                <Button type="primary" onClick={resetToStart}>
                  처음으로
                </Button>
              </div>
            ) : (
              <div className="flex justify-center mt-5">
                <Button type="primary" onClick={exportToExcel}>
                  리드배분 목록 다운로드
                </Button>
                <Button onClick={resetToStart} className="ml-5">
                  확인
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      {shouldShowTable && (
        <div className="footer">
          <Button size="large" className="w-1/12" onClick={resetToStart}>
            취소
          </Button>
          <Button
            size="large"
            type="primary"
            className="ms-5 w-1/12"
            onClick={handleFinalCreate}
          >
            등록
          </Button>
        </div>
      )}
      <Modal
        title={
          <div className="flex">
            <img
              alt="wrong circle icon"
              src={require("../../../assets/icons/close-circle.png")}
              style={{ width: 22, height: 22, marginRight: 16 }}
            />
            <div>
              <p className="text-base font-normal">
                데이터가 유효하지 않습니다.
              </p>
              <p className="text-sm font-normal mt-1">엑셀 파일 다시 업로드</p>
            </div>
          </div>
        }
        open={modalOpen === 1}
        closable={false}
        footer={
          <div className="flex justify-end mt-5">
            <Button
              className="me-3"
              onClick={() => {
                setModalOpen(0);
                setDataErrorList([]);
              }}
            >
              취소
            </Button>
            <Upload beforeUpload={handleFileUpload} showUploadList={false}>
              <Button type="primary" danger>
                엑셀 파일 다시 업로드
              </Button>
            </Upload>
          </div>
        }
      >
        <Table
          columns={errorColumns}
          dataSource={dataErrorList}
          size="small"
          scroll={{ x: "max-content", y: 236 }}
          pagination={false}
        />
      </Modal>
    </div>
  );
};

export default LeadRegistration;
