import {
  Button,
  Table,
  Drawer,
  Form,
  Select,
  Input,
  Space,
  InputRef,
  message,
} from "antd";
import { ColumnsType } from "antd/es/table";
import type { FilterDropdownProps } from "antd/es/table/interface";
import type { ColumnType } from "antd/es/table/interface";
import "./MediaCode.scss";
import requiredRule from "../../../utils/formRules/requiredRule";
import { MediaCodeDataType, MediaCodeOptionsDataType } from "./types";
import { checkCLMediaNameFormat } from "./utils/checkCLMediaNameFormat";
import { LeadTypeOptions } from "./options";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";
import { Dispatch, SetStateAction } from "react";
import { useReactiveVar } from "@apollo/client";
import { __currentUser__ } from "../../../graphql/policies";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import { ServiceTypes } from "../../../types/types";
import { apiCall } from "../../../utils/apiCall";
import { useState, useEffect, useRef } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

interface MediaCodeProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const MediaCode = ({
  setSelectedService,
  setSelectSubItem,
}: MediaCodeProps) => {
  const [mediaCodeList, setMediaCodeList] = useState<MediaCodeDataType[]>([]);
  const [mediaCodeOptionsList, setMediaCodeOptionsList] =
    useState<MediaCodeOptionsDataType>();
  const [selectedLeadType, setSelectedLeadType] = useState("");
  const [selectedMediaCode, setSelectedMediaCode] =
    useState<MediaCodeDataType>();
  const [showDrawer, setShowDrawer] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const currentUser = useReactiveVar(__currentUser__);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 5,
    },
  });

  const searchInput = useRef<InputRef>(null);
  const handleSearch = (
    selectedKeys: any,
    confirm: Function,
    dataIndex: string
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: Function) => {
    clearFilters();
    setSearchText("");
  };

  const [form] = Form.useForm();

  const header = {
    headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
    },
  };

  const fetchListData = async () => {
    const data = await apiCall({
      method: "GET",
      header: header,
      endPoint: process.env.REACT_APP_SALES_ADMIN + "/leads/admin/media",
    });
    if (data) {
      setMediaCodeList(data);
    }
  };

  const fetchOptionData = async () => {
    const data = await apiCall({
      method: "GET",
      header: header,
      endPoint:
        process.env.REACT_APP_SALES_ADMIN + "/leads/admin/options/media-code",
    });
    if (data) {
      setMediaCodeOptionsList(data);
    }
  };

  const handleClose = () => {
    setIsEditMode(true);
    setShowDrawer(false);
    setSelectedLeadType("");
    setSelectedMediaCode(undefined);
    form.resetFields();
  };

  const getColumnSearchProps = (dataIndex: string): ColumnType<any> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: FilterDropdownProps) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as string}
          onChange={(e) =>
            setSelectedKeys(
              e.target.value ? [e.target.value] : ([] as string[])
            )
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0] as string);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes(value.toString().toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async () => {
        const { mediaCode, mediaName, leadType } = form.getFieldsValue();
        const payload = {
          mediaName: mediaName,
          ...(isEditMode
            ? {
                categoryId: selectedMediaCode?.categoryId,
              }
            : {
                mediaCode: mediaCode,
                mediaLeadType: LeadTypeOptions.find((i) => i.value === leadType)
                  ?.label,
              }),
        };
        await apiCall({
          method: isEditMode ? "PATCH" : "POST",
          endPoint: process.env.REACT_APP_SALES_ADMIN + "/leads/admin/media",
          header: header,
          payload: payload,
        });

        fetchListData();
        fetchOptionData();
        handleClose();
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    fetchListData();
    fetchOptionData();
    setSelectedService(ServiceTypes.SALES_188);
    setSelectSubItem("시스템 관리");
  }, []);

  useEffect(() => {
    console.log(selectedMediaCode);
    if (selectedMediaCode) {
      form.setFieldsValue({
        leadType: LeadTypeOptions.find(
          (i) => i.label === selectedMediaCode?.leadType
        )?.value,
        mediaName: selectedMediaCode?.mediaName,
        mediaCode: selectedMediaCode?.mediaCode,
      });
      setSelectedLeadType(
        LeadTypeOptions.find((i) => i.label === selectedMediaCode?.leadType)
          ?.value || ""
      );
    }
  }, [selectedMediaCode, form]);

  const columns: ColumnsType<MediaCodeDataType> = [
    {
      title: "#",
      render: (_, record) => record.rowNumber,
    },
    {
      title: "리드타입",
      dataIndex: "leadType",
      key: "leadType",
      filters: [
        { text: "CL_MEDIA", value: "CL_MEDIA" },
        { text: "DL_BD", value: "DL_BD" },
        { text: "DL_GD", value: "DL_GD" },
        { text: "DL_LD", value: "DL_LD" },
      ],
      onFilter: (value, record) => record.leadType === value,
    },
    {
      title: "미디어코드",
      dataIndex: "mediaCode",
      key: "mediaCode",
    },
    {
      title: "미디어명",
      dataIndex: "mediaName",
      key: "mediaName",
      ...getColumnSearchProps("mediaName"),
    },
    {
      title: "생성일",
      dataIndex: "createDate",
      key: "createDate",
      width: 120,
    },
    {
      title: "수정",
      width: 100,
      render: (record) =>
        record.leadType === "DL_GD" ? (
          <></>
        ) : (
          <Button
            type="link"
            onClick={() => {
              setShowDrawer(true);
              setSelectedMediaCode(record);
              setIsEditMode(true);
              setSelectedLeadType(
                LeadTypeOptions.find((i) => i.label === record.leadType)
                  ?.value || ""
              );
              form.resetFields();
            }}
          >
            수정
          </Button>
        ),
    },
  ];

  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "System Management"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div id="mediaCodeWrapper">
      <div className="flex justify-between items-center px-6 py-4 bg-white">
        <div className="flex items-center gap-2 text-xl font-semibold text-textPrimary">
          <h2>분류 관리 - 미디어코드</h2>
        </div>
        <Button
          onClick={() => {
            setShowDrawer(true);
            setIsEditMode(false);
          }}
        >
          신규 미디어 등록
        </Button>
      </div>
      <div className="content-container">
        <div className="flex items-center justify-between">
          <p className="text-base">미디어코드</p>
          <div className="flex">
            <img
              onClick={fetchListData}
              className="icon cursor-pointer"
              alt="refresh-icon"
              src={require("../../../assets/icons/refresh.png")}
            />
            <img
              className="icon"
              alt="expand-icon"
              src={require("../../../assets/icons/expand.png")}
            />
          </div>
        </div>
        <Table
          className="mt-5"
          columns={columns}
          dataSource={mediaCodeList.map((item, index) => ({
            ...item,
            rowNumber: index + 1,
          }))}
          pagination={{
            showTotal: (total) => `Total ${total} items`,
          }}
          scroll={{ x: "max-content", y: 430 }}
        />
      </div>
      <Drawer
        open={showDrawer}
        title={isEditMode ? selectedMediaCode?.mediaName : "신규 미디어 등록"}
        onClose={handleClose}
        footer={
          <div className="flex justify-end">
            <Button className="mr-3" onClick={handleClose}>
              취소
            </Button>
            <Button type="primary" onClick={handleSubmit}>
              등록
            </Button>
          </div>
        }
      >
        <Form form={form} layout="vertical">
          <Form.Item label="리드타입" rules={[requiredRule]} name="leadType">
            <Select
              disabled={isEditMode}
              placeholder="리드타입을 선택해주세요."
              options={LeadTypeOptions}
              onSelect={(v) => {
                setSelectedLeadType(v);
              }}
            />
          </Form.Item>
          <Form.Item
            label="미디어명"
            rules={[
              requiredRule,
              {
                validator: (_, value) => {
                  if (
                    selectedLeadType === "CL" &&
                    value &&
                    !checkCLMediaNameFormat(value)
                  ) {
                    return Promise.reject(
                      "미디어명은 '_DEMO', '_DIP', '_SNS', '_PA', '_DB' 중 하나로 끝나야 합니다."
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            name="mediaName"
          >
            <Input placeholder="미디어명을 입력해주세요." />
          </Form.Item>
          {selectedLeadType === "CL" && (
            <p
              style={{
                color: "rgba(0, 0, 0, 0.45)",
                marginBottom: 20,
                marginTop: -10,
                fontSize: 12,
              }}
            >
              For CL_MEDIA, media name must end with _DEMO/_DIP/_SNS/_PA/_DB
            </p>
          )}
          <Form.Item label="미디어코드" rules={[requiredRule]} name="mediaCode">
            {selectedLeadType === "LD" ? (
              <Select
                disabled={isEditMode}
                placeholder="미디어코드를 입력해주세요."
                options={
                  mediaCodeOptionsList
                    ? [
                        {
                          label: mediaCodeOptionsList["LD"],
                          value: mediaCodeOptionsList["LD"],
                        },
                      ]
                    : []
                }
              />
            ) : selectedLeadType === "BD" ? (
              <Select
                disabled={isEditMode}
                placeholder="미디어코드를 입력해주세요."
                options={
                  mediaCodeOptionsList
                    ? mediaCodeOptionsList["BD"].map((i) => {
                        return {
                          label: i,
                          value: i,
                        };
                      })
                    : []
                }
              />
            ) : (
              <Input
                disabled={!selectedLeadType || isEditMode}
                placeholder="미디어코드를 입력해주세요."
              />
            )}
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default MediaCode;
